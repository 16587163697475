.localVideo {
  position: relative;
  width: 100%;
  height: 95%;
  background: none;

  .video {
    height: 75%;
    text-align: center;
    & video{
      position: auto;
      width: initial;
    }
  }
  .fullVideoBtnHide {
    display: none;
  }
  
  .offFullVideoBtn {
    cursor: pointer;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    top: -10%;
    &.offFullVideoBtnHide {
      display: none;
    }
    img {
      position: absolute;
      width: 50%;
    }
    img:last-child:hover {
      opacity: 0;
    }
  }
  .fullVideoBtn {
    cursor: pointer;
    position: absolute;
    width: 15%;
    height: 15%;
    margin: 10px;
    right: 0px;
    top: 0px;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0px;
    }
    img:last-child:hover {
      opacity: 0;
    }
  }
}