.shapeSelector {
	margin: 10px 0;
	.buttonGroup{
		display:flex;
		.button{
			display:flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			cursor: pointer;
			& + .button {
				margin-left: 8px;
			}
			&.active {
				background-color: rgba(255,255,255,0.1);
			}
		}
	}
}