.contentWrapper{
  position: relative;
  display: flex;
  flex: 1;
	overflow: hidden;
  .contentShare{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000000;
  }
}

.LiveStreamContent {
  display: flex;
  height: 100%;
  &.studentMode {
    display: flex;
  }
  &.otherMode {
    // display: inline;
  }
}
