.areaZoomController{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: flex-end;
    pointer-events: none;

    .prevNextContent{
        display: flex;
        width: 80%;
        justify-content: center;
        align-items: center;
    }

    .btn{
        pointer-events: all;
        cursor: pointer;
        width: 7vmin;
        display: none;
        justify-content: center;
        align-items: center;

        img{
            width: 100%;
        }

        &.prev{
            //top: 0px;
            //left: 50%;
        }

        &.next{
            //bottom: 12%;
            //left: 50%;
        }

        &.show{
            display: flex;
        }
    }

    .leftExit{
        width:10%;

        .btn{
            display: block;
        }
        
    }

    .rightExit{
        width:10%;
        display: flex;
        justify-content: flex-end;

        .btn{
            display: block;
        }
    }
}