.textPanel{
  color: #91949E;
  font-weight: bold;
  .group {
    padding: 5px 0;
    .select{
      margin: 10px 0;
      width: 100px;
      min-width: 70px;
    }
    .title{
      font-size: 15px;
    }
  }
  
  .textStyles{
    display: inline-flex;
    align-items: center;
    width: 100%;
    .fontSizeSelector{
      width: 100px;
      margin: 0;
    }
    .option {
      padding: 8px 20px;
      &.active {
        color: #fff;
      }
    }

    .textStyle{
      display: inline-flex;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      cursor: pointer;
      &.active {
        background-color: rgba($color:#fff, $alpha: 0.1);
      }

      & + .textStyle{
        margin-left: 8px;
      }
    }
  }
}
