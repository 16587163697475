
	.radio{
    display: flex;
    align-items: center;
    margin-top: 5px;
		.label{
      display: flex;
      align-items: center;
      font-size: 14px;
			color: #fff;
			cursor: pointer;
			&	+ .label {
				margin-left: 15px;
			}
		}
	}