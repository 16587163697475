@import '../../style/media';

.index {
  display: flex;
  justify-content: center;

  .container {
    width: 100%;
    box-shadow: 0px 0px 10px #ccc;
    padding: 20px 0;
  }

  .list-container {
    width: 100%;
    max-width: 1200px;
    margin: 15px auto;
    padding: 1%;
    list-style: none;
    // border: 0.2rem solid #ccc;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    // grid-gap: 20px;
    display: flex;
    // align-items: stretch;
    flex-wrap: wrap;

    .list-item {
      margin-bottom: 10px;
      margin-right: 10px;

      .list-main {
        width: 220px;
        height: 300px;
        color: #fff;
        border: 0.1rem solid #ccc;
        border-radius: 0.2rem;
        background: linear-gradient(90deg, #fff 20%, #f4f4f4 80%);
        transition: all .3s ease;
        cursor: pointer;
        // grid-template-rows: repeat(auto-fit, minmax(2, 1fr));
        // grid-template-columns: 1fr;
        // grid-auto-rows: minmax(100, auto);

        .list-img {
          width: 100%;
          height: 0;
          padding-bottom: 115%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .list-title {
          // height: 13.33%;
          line-height: 1.3;
          padding: 0.5rem 0.5rem 0.2rem;
          // padding-top: 0.5rem;
          // padding-bottom: 0.3rem;
          color: #000;
          display: flex;
          align-items: flex-end;
          justify-content: center;
        }

        &:hover {
          transform: scale(1.05);
        }
        @include tablet {
          margin: 1.5%;
        }
        @include phone {
          margin: 3% auto;
        }
      }

      .list-buttonBox {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        margin-top: 10px;

        & > div {
          width: 49%;
          display: flex;
          flex-direction: column;

          & > div {
            flex: 1;
          }
        }

        .list-button {
          border: 2px solid #ea7474;
          background-color: #fff;
          color: #ea7474;
          font-weight: bold;
          border-radius: 5px;
          width: 100%;
          cursor: pointer;
          display: flex;
          align-items: center;
          transition: 0.5s;
          justify-content: center;

          &.big {
            font-size: 24px;
          }

          &:hover {
            background-color: #ea7474;
            color: #fff;
          }

          & + div {
            margin-top: 5px;
          }
        }
      }
    }
  }

  .default {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 35px 0;

    .title {
      font-size: 8vmin;
    }

    .sub_title {
      margin: 35px 0;
      font-size: 4vmin;
    }

    .go_box {
      margin: 20px 0;
      width: 100%;
      display: flex;
      justify-content: center;

      .go_onemall {
        padding: 5px 10px;
        color: #fff;
        background-color: #ccc;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          color: #000;
          background-color: #f4f4f4;
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  .version-info {
    text-align: right;
    padding-right: 10px;
  }
}

.dialogButton {
  border: solid;
  color: blue;
}

.progressContent {
  width: 25vh;

  .text {
    display: flex;
    align-items: center;
  }

  .progress {
    width: 100%;
  }
}

.loadingContent {
  width: 25vh;

  .text {
    text-align: center;
  }

  .loading {
    text-align: center;
    padding-top: 20px;
  }
}

.filter {
  display: flex;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;

  button {
    width: auto;
    height: auto;
  }
}

.noResult {
  max-width: 1200px;
  margin: 25px auto;
  font-size: 35px;
  text-align: center;
}