@import '../../style/media';

.listeningStory {
  display: flex;
  flex-flow: row-reverse;
  overflow: auto;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  ruby {
    rt {
      transform: translateX(10px);
    }
  }

  .tab {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .list {
      width: 8vmin;
      font-size: 3vmin;
      border-radius: 10px;
      color: #000;
      background-color: #5eeae3;
      padding: 2vmin 3vmin;
      margin: 1vmin 0;
      cursor: pointer;

      &.active {
        background-color: #f98fb8;
        cursor: default;
      }
    }
  }

  .contentBox {
    width: 90%;
    height: 100%;
    display: flex;
    flex-flow: row-reverse;

    .phoneticImg {
      flex: .2;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 6vmin;
      }
    }

    .contentImgs {
      flex: .3;
      height: 100%;
      margin: 0 .5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 0;

      .img {
        width: 22vmin;

        img {
          width: 100%;
        }
      }
    }

    .contentTexts {
      flex: 2;
      display: flex;
      flex-direction: row-reverse;
      overflow-x: scroll;
      overflow-y: hidden;
      backface-visibility: hidden;

      .textBox {
        // flex: 1;
        margin: 0 1vmin;
        height: 100%;
        display: flex;
        writing-mode: vertical-rl;
        flex-direction: row;

        & > div {
          height: 100%;
          display: inline-block;
        }

        // 大中小
        &.small {
          // transition: .1s;

          .radio {
            margin-right: 3.5vmin;
          }
        }

        &.medium {
          // transition: .1s;

          .radio {
            margin-right: 5.5vmin;
          }
        }

        &.large {
          // transition: .1s;

          .radio {
            margin-right: 7.5vmin;
          }
        }

        .radio {
          display: flex;
          justify-content: flex-start;
          margin-right: 6vmin;
          // padding-bottom: 2vmin;
          height: 12.5%;
          @include tablet {
            .audioControlsButton {
              min-width: 40px;
              min-height: 40px;
            }
          }
        }
      }
    }
  }
}