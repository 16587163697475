@import '../../../style/media';

.readerTools {
	position: relative;
	display: flex;
	justify-content: space-between;
	background-color: #232323;
	width: 100%;
	height: 80px;
	padding: 0 32px;
	z-index: 100000;
	.toolBar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 100%;
	
		.toolsGroup{
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;

			// .tool	{
			// 	display: flex;
			// 	justify-content: center;
			// 	align-items: center;
			// 	width: 32px;
			// 	height: 32px;
			// 	margin: 0 15px;
			// 	cursor: pointer;
			// 	> svg {
			// 		width: 100%;
			// 		height: 100%;
			// 	}

			// 	&.active {
			// 		> svg {
			// 			path {
			// 				fill: #EC7963;
			// 			}
			// 		}
			// 	}
			// }

			.divide{
				width: 1px;
				height: 24px;
				margin: 0 8px;
				background-color: #454b5c;
			}

			&.left {
				.tool {
					width: auto;
				}
			}
		}
	}
	
	&.hide{
		position: relative;
		-webkit-filter: grayscale(1);
		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: #000;
			z-index: 1000;
			opacity: .4;
			cursor: not-allowed;
		}
		 * {
			pointer-events: none;
		}
	}

	.adminText{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 100%;
		color: white;
		font-size: 50px;
	}

	.text {
		width: 100%;
		font-size: .83vmax;
		@media screen and (max-width: 1280px) and (min-width: 1024px) {
			font-size: 12px
		}
	}

	.toolBtn {
		position: relative;
		min-width: auto;
	}

	.addBtn {
		width: 100%;
		height: 100%;
		color: #fff;
		position: relative;
		overflow: hidden;
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 10px;
	}

	.addFileChange {
		position: absolute;
		top: 0;
		right: 0;
		min-width: 100%;
		min-height: 100%;
		font-size: 100px;
		text-align: right;
		filter: alpha(opacity=0);
		opacity: 0;
		outline: none;
		background: white;
		cursor: inherit;
		display: none;
	}
}


.mobileReaderTools {
	position: fixed;
	right: 10px;
	bottom: 10px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid #ccc;
	box-shadow: 1px 1px 1px 1px #ccc;
	background-color: #fff;
}

.toolbox{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 -10px;
	.tools{
		margin: 10px;
		button {
			font-size:.83vmax;
		}
	}
}


// 4K screen
// @media screen and (min-width: 3000px) {
// 	.readerTools {
// 		height: 300px;
// 	}
// }

// 1280 screen
@media screen and (max-width: 1280px) {
	.readerTools {
		height: 11vh;
	}
}