
@keyframes loading {
  0 {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadWrapper{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.75);
  z-index: 10000;
}
.load {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .ring {
      width: 80px;
      height: 80px;
      margin: 0 auto;
      padding: 10px;
      border: 7px dashed #FF6700;
      border-radius: 100%;
      animation: loading 3s linear infinite;
  }  
}
