.chatRoom {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  background-color: #232323;
  height: 100%;

  .messagePanel {
    overflow-y: scroll;
    background-color: #232323;
    height: 100%;
    &::-webkit-scrollbar{
			width: 1px;
			background-color: #232323;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #232323;
		}
  }

  .controls {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 5px 0px;
    color: #000;
    justify-content: center;

    .messageInput {
      display: inline-flex;
      align-items: center;
      height: 100%;
      width: 100%;
      margin: 0 auto;
      & label{
        width: 100%;
        & input{
          width: 100%;
          padding: 12px 30px 12px 16px;
          font-size: 14px;
          color: #fff;
          background-color: #33353A;
          border: none;
          &:active, &:focus{
            outline: 0;
          }
          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #A1A4B1;
            opacity: 1; /* Firefox */
          }
          
          &::-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #A1A4B1;
          }
          
          &::-ms-input-placeholder { /* Microsoft Edge */
            color: #A1A4B1;
          }
        }
      }
    }

    .sendButton {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      display: inline-flex;
      align-items: center;;
      cursor: pointer;
    }
  }
}