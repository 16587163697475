@import '../../../style/media';

$fontFamily: 'Microsoft JhengHei', 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

.loginPopup__background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(0,0,0,.5);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $fontFamily;
    transition:opacity 0.5s;
    opacity: 1;
    overflow: hidden;
    &.active {
        width: 0;
        height: 0;
        opacity: 0;
    }
    .loginPopup__box{
        max-width: 510px;
        width: 100%;
        padding: 90px 70px 80px;
        background-image: linear-gradient(#52c4fa, #fa74b4);
        border-radius: 0.5rem;
        margin:auto;
        position: relative;
        z-index: 1001;
        @include phone{
            padding: 90px 30px 80px;
            width: 95%;
        }
        .loginPopup__close {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 1001;
            cursor: pointer;
            padding:8px 14px;
            border-radius: 50%;
            font-weight: 600;
            font-size:1.2rem;
            color:#fff;
            background-color: #d42d26;
            &:hover {
                background-color: #f8bf01 !important;
                box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                transition: all 0.35s cubic-bezier(.25,.8,.25,1);
            }
        }
        .loginPopup__message {
            margin: 15px auto;
            padding: 10px;
            text-align: center;
            background-color: #f8bf01;
            color:#fff;
            border-radius: 5px;
            font-size: 0.8rem;
        }
        h5 {
            width: 100%;
            text-align: center;
            color:#fff;
            font-size: 22px;
            margin-right: 15px;
            padding-bottom: 5px;
            margin: 0;
            display: inline-block;
            border-bottom: 2px solid transparent;
        }
        .loginPopup__btn {
            width: 100%;
            display: flex;
            cursor: pointer;
            align-items: flex-start;
            justify-content: center;
            text-decoration: none;
            color:#fff;
            padding: .75rem .85rem !important;
            margin-bottom: 15px;
            border-radius: 5px !important;
            font-weight: 700;
            transition:0.35s;
            img {
                width: 24px;
                margin-right: 10px;
            }
            &.loginPopup__btnblue {
                background-color: #4582ec;
                border-color: #4582ec;
                &:hover {
                    background-color: #0950ca !important;
                    border-color: #003aa5 !important;
                }
            }
            &.loginPopup__btnred {
                background-color: #d42d26;
                border-color: #d42d26;
                &:hover {
                    background-color: #f8bf01 !important;
                    border-color: #fac003 !important;
                }
            }
        }
        .loginPopup__inputbox{
            // padding: 15px 0 10px;
            h6 {
                margin: 0;
                color:#fff;
                font-weight: 700;
                margin-bottom: 5px;
                font-size: 1.1em;
            }
            .loginPopup__attion {
                color:#fff;
                font-size: 0.8rem;
            }
            input {
                width: 100%;
                padding:8px 5px;
                font-family: $fontFamily;
            }
            span {
                display: block;
                color:#dc3545;
                font-weight: 700;
                font-size: 0.7rem;
                height: 16px;
            }
        }
        .loginPopup__remeber {
            color:#fff;
            display: flex;
            width: 100%;
            align-items: center;
            margin-bottom: 20px;    
        }
        .loginPopup__link {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0;
            a {
                color:#fff;
                text-decoration: none;
                padding: 0 10px;
                & + a {
                    border-left: 1px solid #fff;
                }
            }
        }
    }
}