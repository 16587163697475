.sidePanel{
  position: absolute;
  bottom: 40px;
  width: 400px;
  min-width: 200px;
  min-height: 200px;
  max-width: 100%;
  background-color: #232323;;
  border-radius: 12px;
  padding: 20px 28px;
  z-index: 10000;
  &.left {
    left: 40px;
  }

  &.right{
    right: 40px;
  }
  .closeBtn {
    position: absolute;
    top: 5px;
    right: 10px;
    color: #fff;
    cursor: pointer;
  }
}