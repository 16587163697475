  .option{
    position: relative;
    width: 100%;
    height: 30px;
    .line{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 70%;
      background-color: #91949E;
    }

    &.active {
      .line {
        background-color: #fff;
      }
    }
  }