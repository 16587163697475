@import '../../style/media';

.readerView {
  display: flex;
  width: 100%;
  // height: calc(100vh - 9vh);
  margin: auto;
  height: 92vh;
  overflow: hidden;
  position: relative;

  .mousePenChangeContent{
    pointer-events: none;
    position: absolute;
    bottom: 0px;
    margin:0 auto;
    width: 100%;
    height: 4%;
    text-align: center;
    &.top{
      top: 0px;
    }
    &.bottom{
      bottom: 0px;
    }
    &.right{
      right:0px;
      display: flex;
      align-items: center;
      width: 2.5%;
      height: 100%;
    }
    &.left{
      left:0px;
      display: flex;
      align-items: center;
      width: 2.5%;
      height: 100%;
    }
  }
  .mousePenChangeBar{
    margin: 0 auto;
    width: 50%;
    height: 100%;
    background-color: rgba(180, 180, 180 ,0.5);
    pointer-events: auto;
    &.right{
      width: 100%;
      height: 70%;
    }
    &.left{
      width: 100%;
      height: 70%;
    }
  }

  &.left,
  &.right {
    height: 100vh;
  }

  @include tablet {
    height: 100%;
  }

  @include phone {
    height: 100%;
  }

  .bookContainer {
    position: relative;
    width: 100%;
  }

  .bookMain {
    width: calc(100% - 64px);
    height: 100%;
    margin: auto;
    z-index: 10;
  }

  &.cursorDrag {
    &,
    canvas {
      cursor: url('/assets/img/cursor/mouse.png'), auto !important;
    }
  }

  &.cursorPainting {
    &,
    canvas {
      cursor: url('/assets/img/cursor/pen.png'), auto !important;
    }
  }

  &.cursorHighlighter {
    &,
    canvas {
      cursor: url('/assets/img/cursor/marker.png'), auto !important;
    }
  }

  &.cursorShape {
    &,
    canvas {
      cursor: url('/assets/img/cursor/shape.png'), auto !important;
    }
  }

  &.cursorLine {
    &,
    canvas {
      cursor: url('/assets/img/cursor/line.png'), auto !important;
    }
  }

  &.cursorDelete {
    &,
    canvas {
      cursor: url('/assets/img/cursor/eraser.png'), auto !important;
    }
  }

  &.cursorIntervalZoomIn {
    &,
    canvas {
      cursor: url('/assets/img/cursor/magnifier.png'), auto !important;
    }
  }

  &.cursorSelect {
    &,
    canvas {
      cursor: url('/assets/img/cursor/select.png'), auto !important;
    }
  }

  &.cursorMark {
    &,
    canvas {
      cursor: url('/assets/img/cursor/marktext.png'), auto;
    }

    text {
      cursor: text;
    }
  }

  .tableContentsMenu {
    overflow-y: hidden !important;
  }
}

.canvas {
  &.alignLeft {
    margin-right: auto;
  }

  &.alignRight {
    margin-left: auto;
  }
}

.ctrlButton {
  position: absolute;
  cursor: pointer;
  top: calc(50% - 23.5px);
  transform: translateY(-50%);
  z-index: 3;
  width: 32px;
  height: 32px;

  &.left {
    left: 1px;
  }

  &.right {
    right: 1px;
  }
}

