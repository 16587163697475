@import '../../style/media';

.wordLearningGrid {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-size: cover;
  background-position: 50% 50%;
  font-family: '微軟正黑體';
  background-color: #ddf3f0;

  .left {
    position: relative;
    flex: 0.45;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: column;
    max-height: 100%;
    padding: 10px;
    direction: rtl;
    transition: .3s;
    width: auto;
    @include tablet {
      flex: 0.3;
    }

    .wordList {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      overflow: hidden;
      font-family: cursive;
    }

    .word {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 7.5vmin;
      height: 7.5vmin;
      margin-bottom: 4px;
      border-radius: 15px;
      overflow: hidden;
      border: 4px solid #a2d7df;
      background-color: #fff;
      @include tablet {
        width: 48px;
        height: 48px;
        margin-bottom: 2px;
      }

      button {
        appearance: none;
        font-size: 5.5vmin;
        color: #000;
        background-color: #fff;
        outline: none;
        font-family: cursive;
      }
      cursor: pointer;

      &.optional {
        button {
          color: #00f;
        }
      }

      &.active {
        background-color: #fff2d9;

        button {
          background-color: #fff2d9;
        }
      }
    }
  }

  .center {
    position: relative;
    flex: 3;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    max-width: 100%;
    height: 100%;
    padding: 0 10px;
    direction: rtl;
    overflow-x: auto;
    overflow-y: hidden;
    @include tablet {
      flex: 2;
    }

    @media screen and (max-width: 1100px) {
      flex: 1.6
    }

    &.images {
      // background-color: rgba(255, 255, 255, 0.7);
    }

    .videoContainer {
      display: block;
      position: relative;
      text-align: center;
      padding: 20px 0px 20px 30px;
      max-width: 100%;
      height: 86%;
      direction: ltr;
      @include tablet {
        height: 82%;
      }

      &.fullSize {
        max-width: 100%;
        height: 100%;
        @media screen and (max-width: 1280px) {
          padding: 25px 0px 25px 0px;
        }
        @media screen and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.50) {
          padding: 0;
        }
        @include tablet{
          padding: 35px 0px 35px 0px;
        }
      }

      .gridWrapper {
        display: inline-block;
        max-width: 100%;
        height: 100%;
        background-color: #fff;
        padding: 20px;
        border-radius: 20px;
        box-shadow: 2px 2px 12px 5px #aaa;
      }

      .grid {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        height: 100%;
        border: 3px solid #fe8bb3;
        padding-right: 3.5vmin;
        background-color: #fff;
        overflow: hidden;
        @media screen and (max-width: 1100px){
          padding-right: 3vmin;
        }
      }

      .bopomofo {
        height: 100%;
        padding-left: 4.2vmin;
        @media screen and (max-width: 1280px) {
          padding-left: 3.2vmin;
        }
      }

      &.fullSize {
        .bopomofo {
          @media screen and (max-width: 1280px) {
            padding-left: 1vmin;
            padding-right: 4.2vmin;
          } 
        }
      }

      rt {
        font-size: 9vmin;
        @media screen and (max-width: 1280px) {
          font-size: 7vmin;
        }
      }

      .video {
        display: inline-flex;
        position: relative;
        height: 100%;
        max-width: 100%;
        border-right: 2px solid #fe8bb3;
        padding: 5px;

        &.volumeOneWord {
          border-right: none;
        }

        &:after,
        &:before {
          content: '';
          position: absolute;
          z-index: 100;
          -webkit-backface-visibility: hidden;
        }

        &.noGrid {
          &:after,
          &:before {
            display: none;
          }
        }

        &.solid {
          &:after,
          &:before {
            background-color: #999;
          }

          &:after {
            width: 2px;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(calc(-50% + 5px));
          }

          &:before {
            height: 2px;
            width: 100%;
            top: 50%;
            left: 0;
            transform: translateY(calc(-50% - 2px));
          }
        }

        &.dotted {
          &:after {
            width: 0px;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(calc(-50% + 5px));
            border: 0.5px dotted #555;
          }

          &:before {
            height: 0px;
            width: 100%;
            top: 50%;
            left: 0;
            transform: translateY(calc(-50% - 2px));
            border: 0.5px dotted #555;
          }
        }
      }

      video {
        height: 100%;
        // object-fit: fill;
        max-width: fit-content;
      }

      .phonetic {
        height: 100%;
        margin-right: -8vmin;

        img {
          margin-right: 4vmin;
          height: 100%;
        }
      }

      .zoomIcon {
        position: absolute;
        bottom: 5px;
        right: 10px;
        font-size: 2rem;
        cursor: pointer;
        color: #fa538d;
      }
    }
    // .image{
    //   max-width: 100%;
    //   padding-bottom: 90%;
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   margin: 0 auto;
    // }
    // flex-direction: row-reverse;
    .image {
      // display: flex;
      width: 100%;
      height: 100%;
      padding: 20px 20px 0 0;

      .wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        direction: ltr;

        img {
          width: 100%;
        }
      }
    }
  }

  .right {
    flex: 0.4;
    padding: 0px 10px 10px;
  }

  section {
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #5eebff;
    overflow: hidden;

    .title {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 0;
      margin: -5px -10px 0;
      color: #fff;
      font-weight: bold;
      font-size: 2.5vmin;
      background-color: #0068bb;
    }

    &.extendSection{
      background-color: #d8d0ff;
      .title{
        background-color: #8682ff;
      }
    }

    &.gridSection{
      background-color: #ffcc35;
      .title{
        background-color: #cc6733;
      }
    }

    .extendTeach {
      padding: 5px 0;
    }
  }

  &.notMac {
    ruby {
      rt {
        transform: translateX(10px);
      }
    }
  }
}
