@import '../../../style/media';

.radicalInfoBar {
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 14%;
  margin: 0 auto;
  direction: ltr;
  @include tablet {
    width: 100%;
    height: 15%;
  }

  .wrapper {
    display: flex;
    border: 5px solid #a2d7df;
    border-radius: 20px;
    height: 100%;
    overflow: hidden;

    &.left {
      width: 25%;
      border-radius: 20px 0 0 20px;
      border-right: 0px;

      .value {
        cursor: pointer;
      }
    }

    &.right {
      width: 75%;
      border-radius: 0 20px 20px 0;
      border-left: 0px;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #38c7ff;
      font-size: 4vmin;
      writing-mode: vertical-rl;
      color: #000;
    }

    .radicalBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: #fff;

      .label {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        writing-mode: vertical-rl;
        height: 100%;
        font-size: 2.8vmin;
        padding: 3px;
        background-color: #a6e6ff;
      }

      .value {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 7.5vmin;
        font-weight: bold;

        ruby {
          font-family: cursive;
        }

        img {
          width: 100%;
        }
      }
      &.pink .value{
        color: #ff79b0;
      }
      &.orange .value{
        color: #F15A24;
      }
    }
  }
}
