@import '../../../../style/media';

.videoModalComponent {
  width: 100%;
  height: 100%;
}

.wordGridConentContainer {
  position: relative;
  display: flex;
  padding-right: 10px;
  background-color: #ddf3f0;

  .setup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 15%;
    position: relative;
    z-index: 1;
    @media (-webkit-device-pixel-ratio: 1) {
      @media screen and (max-width: 1440px) {
        width: 15%;
      }
      @media screen and (max-width: 1280px) {
        width: 20%;
      }
      @media screen and (max-width: 1024px) {
        width: 15%;
      }
    }
    @media (-webkit-device-pixel-ratio: 1.25){
      @media screen and (max-width: 1280px) {
        width: 15%;
      }
    }
    @media (-webkit-device-pixel-ratio: 1.5){
      @media screen and (max-width: 1440px) {
        width: 15%;
      }
    }
    .breakAnchor {
      flex: 1;
      overflow-y: auto;
      margin-bottom: 10px;
      overflow-x: hidden;
    }

    .controls {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-end;
      background-color: #5eebff;
      padding: 10px;
      border-radius: 10px;
      z-index: 1;

      &.notWordsSet {
        flex: none;
        margin-top: auto;;
      }

      .words {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;

        .word {
          width: calc(50% - 2px);
          padding: 10px;
          margin-bottom: 10px;
          margin-right: auto;
          margin-left: auto;
          background-color: #fff;
          font-size: 3vmin;
          font-weight: bold;
          border-radius: 5px;
          text-align: center;
        }
      }

      .extensionButtons {
        width: 100%;
        border-radius: 5px;

        .button {
          padding: 10px 0;
          text-align: center;
          margin: 5px 0;
          border-radius: 20px;
          background-color: #fff;
          cursor: pointer;
          font-size: 3vmin;

          &:hover {
            box-shadow: 0px 0px 3px #aaa;
          }

          &.active {
            background-color: #fef0c3;
            box-shadow: 0px 0px 3px #aaa;
          }

          &.disabled {
            color: #818181;
            background-color: #cdcdcd;
            cursor: not-allowed;
          }
        }
      }
    }

    // windows 顯示器 100%以上(含)
    @media (-webkit-min-device-pixel-ratio: 1) {
      .breakAnchor {
        .anchor {
          font-size: 3vmin;
        }
      }

      .controls {
        .extensionButtons {
          .button {
            padding: 5px 0;
            font-size: 2.5vmin;
          }
        }
      }

      @media screen and (max-width: 1280px) {
        .breakAnchor {
          .anchor {
            font-size: 2.5vmin;
          }
        }
      }
    }

    // windows 顯示器 125%以上(含)
    @media (-webkit-min-device-pixel-ratio: 1.25) {
      .breakAnchor {
        .anchor {
          font-size: 2.5vmin;
        }
      }

      .controls {
        .extensionButtons {
          .button {
            padding: 5px 0;
            font-size: 2.5vmin;
          }
        }
      }
    }

    // windows 顯示器 150%以上(含)
    @media (-webkit-min-device-pixel-ratio: 1.50) {
      .breakAnchor {
        .anchor {
          font-size: 2vmin;
        }
      }

      .controls {
        .extensionButtons {
          .button {
            padding: 5px 0;
            font-size: 3vmin;
          }
        }
      }
    }

    // windows 顯示器 175%以上(含)
    @media (-webkit-min-device-pixel-ratio: 1.75) {
      .breakAnchor {
        .anchor {
          font-size: 2vmin;
        }
      }

      .controls {
        .extensionButtons {
          .button {
            padding: 5px 0;
            font-size: 2.5vmin;
          }
        }
      }
    }
  }

  .meaningContentWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    // width: calc(100% - 100px);
    width: 85%;
    height: 100%;
    padding-right: 10px;
    z-index: 10;
    @media (-webkit-device-pixel-ratio: 1) {
      @media screen and (max-width: 1440px) {
        width: 85%;
      }
      @media screen and (max-width: 1280px) {
        width: 80%;
      }
      @media screen and (max-width: 1024px) {
        width: 85%;
      }
    }
    @media (-webkit-device-pixel-ratio: 1.25){
      @media screen and (max-width: 1280px) {
        width: 85%;
      }
    }
    @media (-webkit-device-pixel-ratio: 1.5){
      @media screen and (max-width: 1440px) {
        width: 85%;
      }
    }
    &.zhuyin {
      width: 100%;
    }

    .wordContentList {
      position: relative;
      z-index: 1;
    }
  }
}

