.teacherVideo{

  margin-bottom: 2%;
  height: 95%;
  &>div:first-child{
      text-align: center;
      position: initial;
      height: 75%;
      &>video{
        position: inherit;
        width: initial;
      }
  }
  
}