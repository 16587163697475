.studentList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateX(-165%) translateY(-50%);
  width: 120%;
  height: 80%;
  max-width: 120%;
  max-height: 100%;
  background-color: #232323;
  border-radius: 12px;
  padding: 16px;
  z-index: 100000;    
  box-shadow: 4px 2px 6px -2px #000000;

  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #454b5c;
    height: 5%;
    .close {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .content {
    height: 85%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 1px;
      background-color: #232323;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #232323;
    }
    .student {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0px 8px 8px;
      height: 15%;
      .name {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .controls {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 100%;
        width: 50%;
        & > div {
          height: 100%;
        }
      }

      &.active {
        color: #ec7963;
        svg path {
          fill: #ec7963;
        }
      }
    }
  }

  .footer {
    display: flex;
    height: 5%;
    font-size: 10px;
    border-top: 1px solid #454b5c;
    padding-top: 4%;
    .info {
      display: flex;
      align-items: center;

      .label {
        color: #91949e;
      }

      & + .info {
        margin-left: 6%;
      }
    }
    .soundControlButton {
      width: auto;
      outline: none;
      border: none;
      appearance: none;
      background: #49c0b8;
      color: #fff;
      cursor: pointer;
      border-radius: 4px;
      &:disabled {
        background-color: #ccc;
      }
    }
  }
}
