.page {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.svgContent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  g path {
    pointer-events: inherit;
  }

  g {
    pointer-events: auto;
  }
}
.canvasObjectSavedSvgContent {
  z-index: 2;
  pointer-events: visible;
}
.canvasSvgContent {
  z-index: 100;
}

.bookViewContainer {
  position: relative;
  height: 100%;
}
.bookViewContainer > div {
  display: flex;
  height: 100%;
}

.reverse {
  flex-direction: row-reverse;
}

.markMode {
  pointer-events: auto;
}
