.videoPlayer {
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);

  .svgContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    direction: ltr;
    svg {
      pointer-events: none;
    }
  }

  &.cursorDrag {
    &,
    canvas {
      cursor: url('/assets/img/cursor/mouse.png'), auto !important;
    }
  }

  &.cursorPen {
    &,
    canvas {
      cursor: url('/assets/img/cursor/pen.png'), auto !important;
    }
  }
}

.video {
  flex: 1 0 75%;
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: space-between;

  .svgWrap{
    position: absolute;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 70px);
  }

  video {
    width: 100%;
    height: calc(100% - 70px);
    &::-webkit-media-controls {
      display: none !important;
    }
    &::-webkit-media-controls-enclosure {
      display: none !important;
    }
    &::-webkit-media-text-track-display {
      display: none;
    }
  }

  .controlBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 1005;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    padding: 10px;

    .left {
      display: flex;
      align-items: center;
    }

    .right {
      display: flex;
      align-items: center;
    }

    .processBar {
      left: 10px;
      width: calc(100% - 20px);
    }
  }
}
