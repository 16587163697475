@import '../../../style/media';

.popover {
  position: absolute;
  width: 70vmin;
  max-width: 600px;
  min-width: 300px;
  padding: 30px;
  border-radius: 12px;
  background-color: #232323;
  z-index: 99999;

  @include phone {
    width: 100%;
  }

  .closeBtn {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    color: #fff;
    cursor: pointer;
  }

  &.top {
    top: 110%;
    left: 50%;
    bottom: initial;
    right: initial;
    transform: translateX(-50%);
  }

  &.bottom {
    bottom: 110%;
    left: 50%;
    top: initial;
    right: initial;
    transform: translateX(-50%);
  }

  &.left {
    top: 50%;
    left: 110%;
    bottom: initial;
    right: initial;
    transform: translateY(-50%);
  }

  &.right {
    top: 50%;
    right: 110%;
    bottom: initial;
    left: initial;
    transform: translateY(-50%);
  }
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.toolTitle {
  padding: 10px 0;
}

.penPanelBtn {
  border-radius: 50%;
  min-width: 4vmin;
  width: 4vmin;
  height: 4vmin;
  margin: 0 5px;
  padding: 5px;
  background-color: #fff;

  &.active{
    background-color: #bbb;
  }
}

.expression{
  max-width: 100%;
  width: 400px;
  .panelTitle{
    font-size: 14px;
    color: #91949e;
  }

  .expressionButtonGroup{
    display: flex;
    justify-content: center;
    margin-top: 28px;
    .expressionBtn {
      width: 47px;
      > img {
        width: 100%;
      }

      & + .expressionBtn {
        margin-left: 30px;
      }
    }
  }
}

.formGroupItemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;

  .formGroupItem {
    flex: 1;
  }

}

.group{
  display: flex;
  justify-content: space-between;
}
