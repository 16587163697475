.emojiCounts {
  display: flex;
  //height: 3%;
  background-color: black;
  .emojiCount {
    display: flex;
    width: 20%;
    margin: 5px;
    & > div {
      margin: 0 auto;
      padding-top: 4px;
    }
    .countText {
      margin: 2px;
    }
  }
}