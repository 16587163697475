// 顯示 答案
.isShowAnswer .listeningStory {
  .article {
    .paragraph {
      .content {
        &.answer {
          opacity: 1;
          height: 100%;

          ruby {
            opacity: 1;

            rt {
              font-size: 2vmin;
            }
          }
        }
      }
    }
  }
}

// 純注音
.isZhuyin .listeningStory {
  .article {
    .paragraph {
      .begin {
        margin-right: -4vmin;

        ruby {
          display: inline-block;
          height: 10vmin;
          padding: 0 3vmin 0 0;
          padding-right: 2vmin;

          rt {
            display: block;
            white-space: nowrap;
          }
        }
      }

      .content {
        line-height: 1.5;

        ruby {
          margin: 1rem 0;
          backface-visibility: hidden;
        }

        .private_name {
          font-size: 4vmin;
        }
      }
    }
  }
}

// 大中小
.small {
  .listeningStory {
    .article {
      ruby,
      sign {
        font-size: 5vmin;

        rt {
          font-size: 1.6vmin !important;
          transition: .01s;
        }
      }

      .img {
        width: 1.8vmin;
      }
    }
  }
  // 純注音
  &.isZhuyin {
    .listeningStory {
      .article {
        sign {
          font-size: 3vmin;
        }

        ruby {
          rt {
            width: 2.5vmin;
            font-size: 2vmin !important;
          }
        }

        .paragraph {
          .begin {
            margin-right: -3vmin;
          }
        }
      }
    }
  }
}

.medium {
  .listeningStory {
    // margin-left: 7vmin;
    // padding-right: 5vmin;

    .article {
      ruby,
      sign {
        font-size: 7vmin;

        rt {
          width: 3vmin;
          font-size: 2.6vmin !important;
        }
      }

      .img {
        width: 3.5vmin;
      }
    }
  }
  // 純注音
  &.isZhuyin {
    .listeningStory {
      .article {
        sign {
          font-size: 5vmin;
        }

        .paragraph {
          .begin {
            margin-right: -4vmin;
          }
        }
      }
    }
  }
}

.large {
  .listeningStory {
    .article {
      ruby,
      sign {
        font-size: 8vmin;

        rt {
          width: 4vmin;
          font-size: 3.6vmin !important;
        }
      }

      .img {
        width: 5.8vmin;
      }
    }
  }
  // 純注音
  &.isZhuyin {
    .listeningStory {
      .article {
        .paragraph {
          .begin {
            sign {
              font-size: 6vmin;
            }
            margin-right: -5vmin;
          }
        }
      }
    }
  }
}

.listeningStory {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding-right: 1vmin;

  .article {
    writing-mode: vertical-rl;
    direction: ltr;
    line-height: 2.1;

    // 字型
    ruby,
    sign {
      font-family: cursive; // 標楷體
      font-size: 7vmin;
      margin: 1vmin 0;

      rt {
        margin: 0 1vmin;
        font-family: BopomofoRuby; // 注音
      }
    }

    .begin {
      margin-bottom: 2vmin;
      color: #fff;
      display: block;

      ruby {
        background-color: #257af2;
        padding: 1vmin;
        border-radius: 10px;

        rt {
          display: none;
          color: #fff;
        }
      }

      &.answerTitle {
        ruby {
          background-color: #ed0386;
        }
      }
    }

    .turn {
      writing-mode: rl;
      padding-right: 0;
      line-height: 1;

      sign {
        margin: 0;
      }
    }

    .content {
      transform-style: preserve-3d;
      display: block;
      height: 100%;

      &.answer {
        cursor: pointer;
        opacity: 0;
        color: #e30185;

        ruby {
          opacity: 0;

          rt {
            font-size: 0;
          }
        }
      }
    }

    .paragraph {
      // display: flex;
      // padding-right: 3vmin;
      // padding-left: 3vmin;
      height: 100%;
    }

    /* 私名號 直線 */
    .private_name {
      border-left: .5vmin solid transparent;
      border-image: url(line.png) 30 10 repeat;

      &.red {
        border-image: url(redLine.png) 30 10 repeat;
      }

      &.pink {
        border-image: url(pinkLine.png) 30 10 repeat;
      }

      &.blue {
        border-image: url(blueLine.png) 30 10 repeat;
      }
    }

    /* 私名號 波浪 */
    .flow_wave {
      border-left: .7vmin solid transparent;
      border-image: url(wave.png) 40 20 round;

      &.red {
        border-image: url(redWave.png) 40 20 repeat;
      }

      &.pink {
        border-image: url(pinkWave.png) 40 20 repeat;
      }

      &.blue {
        border-image: url(blueWave.png) 40 20 repeat;
      }
    }

    // 注音圖片
    .img {
      display: inline-block;
      width: 3.5vmin;
      margin: 1vmin 0;
      text-indent: 0;

      img {
        width: 100%;
      }
    }
  }
}
