.img {
	width: 100%;
	height: 100%;
}

.svg {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	svg {
		width: 100%;
		height: 100%;
	}
}