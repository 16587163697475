.boxModal {
  position: fixed;
  display: inline-flex;
  flex-direction: column;
  background-color: #51464c;
  box-shadow: 2px 2px 10px #333;
  border-radius: 20px;
  padding: 10px;
  overflow: hidden;
  top: 50%;
  left: 50%;
  z-index: 100000;
  .content {
    display: inline-block;
    position: relative;
    resize: both;
    overflow: hidden;
    width: 40vw;
    height: 30vw;
    .expandIcon{
      position: absolute;
      right: 0px;
      bottom: 0px;
      // pointer-events: none;
      color: #fff;
      background-color: transparent;
      transform: rotate(90deg);
    }
  }
  .controlHead{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    border-bottom: 1px solid #555;
    padding-bottom: 5px;
    cursor: move;
    .closeButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 12px;
      margin-left: auto;
      box-shadow: 1px 1px 5px #333;
      background-color: #fff;
      font-size: 3vmin;
      color: #ec6464;
      cursor: pointer;
    }
  }
}