.synopsisWindow{
  display: flex;
  flex-direction: column-reverse;
  

  .tab_box{
    display: flex;
    justify-content: center;

    .tab{
      min-width: 50px;
      padding: 10px;
      background-color: #1abcbd;
      color: #fff;
      border-radius: 5px;
      margin: 10px;
      text-align: center;
      font-size: 3.5vmin;
      cursor: pointer;

      &.active{
        background-color: #d65f84;
      }
    }
  }

  .list{
    width: 100%;
    margin-right: auto;
    height: 100%;
    overflow: auto;

    &>div{
      height: 100%;
    }
  }

  // For 108上直書 & 國語樣式
  &.wordSection,&.ChineseElementary{
    flex-direction: row;

    .tab_box{
      // max-width: 50px;
      column-count: 1;
      column-gap: 10px;
      display: block;
      justify-content: initial;

      .tab{
        margin-left: auto;
        direction: ltr;
        writing-mode: vertical-rl;
      }
    }

    .list{
      width: 85%;
    }
  }

  // For 國文樣式
  &.ChineseSecondary{
    flex-direction: row-reverse;

    .tab_box{
      max-width: 50px;
      column-count: 1;
      column-gap: 10px;
      display: block;
      justify-content: initial;
      margin-left: auto;
    }

    .list{
      width: 83%;
      margin-right: inherit;
      margin-left: 3vmin;
    }
  }
}
