.painterMain {
  position: absolute;
  width: 400px;
  max-width: 100%;
  padding: 30px;
  border-radius: 5px;
  background-color: #232323;
  z-index: 100000;
  bottom: 1%;

  & > * {
    padding-left: 0 !important;
  }

  .closeBtn {
    color: #fff;
    position: absolute;
    top: 5px;
    right: 10px;
    width: auto;
    cursor: pointer;
  }

  &.left {
    left: 10px;
  }

  &.right {
    right: 10px;
  }
}

.buttonGroup {
  display: flex;
  align-items: center;
}

.division_line {
  width: 100%;
  height: 1px;
  background: #ccc;
  margin: 10px 0;
}
