.wrapper {
  height: 100%;
}

.remoteVideoContainer {
  width: 50%;
  height: 100%;
  position: absolute;
  left: -50%;
  z-index: 10000;
  pointer-events: none;
  & > div {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: inline-block;
    & > div {
      max-width: 100%;
      video {
        position: initial;
      }
      & > div {
        left: 2.5rem;
        color: white;
        font-size: 0.3rem;
      }
    }
  }
}

.dialogAskButton{
  width: 100%;
  display: flex;
  justify-content: center;
}

.chatContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .toolsControls {
    height: 20%;
    padding-top: 10px;
    .studentToolsControl {
      display: flex;
      button {
        width: 70px;
        height: 25px;
      }
    }

    .videoDeviceSelectComponent {
      width: 75%;
    }
  }

  .localVideo {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 20%;
    margin-bottom: 5px;
    & > div {
      cursor: pointer;
      padding-right: 5px;
    }
    button {
      width: 25%;
      height: 25px;
    }
  }

  .localVideoSelect {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
    & > div {
      //cursor: pointer;
      padding-right: 5px;
    }
    button {
      width: 25%;
      height: 25px;
    }
  }

  .localAudio {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
    & > div {
      cursor: pointer;
      padding-right: 5px;
    }
    button {
      width: 25%;
      height: 25px;
    }
  }

  .offCourse {
    display: flex;
    justify-content: center;
    width: 100%;
    img {
      cursor: pointer;
      width: 40%;
      padding-right: 5px;
      position: absolute;
      left: 15%;
    }
  }

  .localVideo {
    position: relative;
    .volume {
      position: absolute;
      bottom: 0px;
      right: 0px;
    }

    .fullVideoMessage {
      position: absolute;
      width: 60%;
      display: flex;
      justify-content: center;
      padding: 23%;
      &.fullVideoMessageHide {
        display: none;
      }
      img {
        position: absolute;
        filter: brightness(0) invert(1);
      }
    }
  }

  .volumeMeter {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .localSound {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
    & > div {
      cursor: pointer;
      padding-right: 5px;
    }
    button {
      width: 25%;
      height: 25px;
    }
  }

  .localVideoContainer {
    width: 75%;
    height: 20%;
    margin: 0 auto;
  }

  .emojiCounts {
    display: flex;
    //height: 3%;
    background-color: black;
    .emojiCount {
      display: flex;
      width: 20%;
      margin: 5px;
      & > div {
        margin: 0 auto;
        padding-top: 4px;
      }
      .countText {
        margin: 2px;
      }
    }
  }
  .chatRoomWrapper {
    height: 35%;
    &.tutoruser {
      height: 35%;
    }
  }
  .teacherVideoContent {
    width: 75%;
    //height: 20%;
    margin: 0 auto;
    .teacherVideo {
      position: relative;
      height: 100%;
      width: 100%;
      .volume {
        position: absolute;
        bottom: 0px;
        right: 5px;
      }
    }
  }
  .attendeeContent {
    width: 75%;
    //height: 20%;
    margin: 0 auto;
    .attendeeVideo {
      position: relative;
      width: 100%;
      .volume {
        position: absolute;
        bottom: 0px;
        right: 5px;
      }
    }
  }
}
