.alertModal{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 600px;
  min-height: 280px;
  max-width: 100%;
  background: #232323;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px 24px;

  .closeIcon{
    position: absolute;
    top: 30px;
    right: 30px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    z-index: 1;
  }

  .content {
    color: #fff;
    padding: 28px 36px 20px;

    .title {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    .message {
      font-size: 14px;
    }
  }

 

  .buttonGroup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;

    .button {
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      border-radius: 5px;
      padding: 8px 22px;

      &.confirm {
        margin-left: 16px;
        color: #EC7963;
        background-color: #33353A;
      }
    }
    
    

  }

}