.wordLearningGrid {
  &.WordRadical {
    display: block;
    font-size: 7vmin;
    height: 95%;

    ruby {
      font-family: cursive;

      rt {
        font-family: BopomofoRuby;
        width: 4vmin;
      }
    }

    div {
      // 注音字型，標楷體
      font-family: BopomofoRuby,cursive;
      margin: 0 .3em;
      line-height: 1.8;
    }

    .article {
      writing-mode: vertical-rl;
      direction: ltr;
      height: 100%;

      .wordBox {
        display: inline-block;
        border-radius: 10px;
        font-size: 10vmin;

        .top {
          background-color: #ee3a79;
          border: 3px solid #ee3a79;
          color: #fff;
          padding: 1vmin 7vmin;
          padding-left: 1vmin;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        .bottom {
          border: 3px solid #ee3a79;
          padding: 1vmin 7vmin;
          padding-left: 1vmin;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }

      .content {
        height: max-content;
        max-height: 92%;
      }

      // 小篆 圖片
      .theLesserSealCharacter {
        img {
          width: 7vmin;
          height: auto !important;
        }
      }
    }
  }
}