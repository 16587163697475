@import '../../style/media';
.pageSearcher {
  flex-direction: column;
  height: calc(100% - 67px - 10px);
  background-color: #33353A;
  padding: 60px 32px 18px;
  .thumbnailWrapper {
    &.horizontal{
      display: flex;
      align-items: flex-start;
      overflow-x: auto;
      &::-webkit-scrollbar{;
        height: 6px;
        background-color: #33353A;
      }
      &::-webkit-scrollbar-thumb {
        width: 100px;
        border-radius: 6px;
        background-color: #8B90A0;
      }
    }
    &.vertical {
      display: block;
      height: 93%;
      overflow-y: auto;
      .input {
        width: 100%;
      }
    }
    &.rightToLeft {
      direction: rtl;
    }

    &.doublePage {
      .thumbnailGroup {
        flex: 0 0 20%;
      }
    }
  }
  .thumbnailGroup {
    flex: 0 0 10%;
    overflow: hidden;


    & + .thumbnailGroup {
      margin-left: 8px;
    }

    .thumbnail {
      display: flex;
      justify-content: center;
      cursor: pointer;
      overflow: hidden;
      min-height: 100%;

      img {
        width: 100%;
        //height: 100%;
        @include phone {
          width: 100%;
          height: 100%;
        }
      }
    }
    &.active .title{
      color: #EC7963;
    }
  }
}

.title {
  text-align: center;
  color: #91949E;
  height: 25px;
  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
  }
}

.input {
  text-align: center;
  label input {
    width: 100%;
    padding: 10px;
    background-color: #232323;
    color: #8B90A0;
    outline: none;
    border: none;
    border-radius: 20px;
    text-align: center;
  }
}

.searchWrap{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 5px 0;

  .goCatalog{
    position: absolute;
    left: 32px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  > div{
    margin: 0;
  }
}