.reader {
	display: flex;
	flex: 1;
	height: 85%;
	overflow: hidden;
	.fullStudentVideo {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 100000;
		& > div {
		  height: 100%;
		  width: 100%;
		  position: relative;
		  background: #1b1c20;
		  /* display: flex; */
		  /* justify-content: center; */
		  /* margin: auto; */
		  padding: 20px;
		  & > video {
			position: static;
		  }
		}
	  }
	  

	&.top {
		flex-direction: column-reverse;
	}

	&.bottom {
		flex-direction: column;
	}

	&.left {
		flex-direction: row-reverse;
	}

	&.right {}

	&.teacherMode{
		height: 100%;
	}

	&.studentMode{
		height: 100%;
	}
	&.otherMode{
		height: 100%;
	}
}