.radio {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
  .dot {
    position: relative;
    background-color: transparent;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    border: 1px solid #A1A4B1;
  }
  .label {
    font-size: 20px;
    padding-left: 5px;
  }

  .dot:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &.active {
    .dot {
      border-color: #EC7963;
    }
    .dot:after {
      background-color: #EC7963;
    }

    &.disabled {
      .dot {
        border-color: #D6DAE0;
      }
      .dot:after {
        background-color: #D6DAE0;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    .dot {
      border-color: #D6DAE0;
    }
  }
}
