.settingPanel {
  position: relative;
}

.panel {
  display: none;
  position: absolute;
  right: 0;
  bottom: 100%;
  width: auto;
  padding: 20px;
  background-color: #333;
  cursor: auto;
  font-size: 20px;

  &.active {
    display: flex;
  }

  .list {
    width: 130px;
    flex: 1;
    color: #fff;

    .listItem {
      position: relative;
      padding: 10px 0;
      cursor: pointer;

      &:hover {
        background-color: #222;
      }

      .label {
        position: relative;
        font-weight: normal;
      }

      &.active .label:after {
        content: '✓';
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        padding-right: 5px;
      }
    }

    .title {
      font-weight: 700;
    }
  }
}



