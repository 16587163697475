.remoteVideo {
    position: relative;
    width: 100%;
    height: 95%;
    background: none;
    &>div:first-child{
        text-align: center;
        position: initial;
        height: 75%;
        &>video{
          position: inherit;
          width: initial;
        }
    }
}