.switchButton{
  display: inline-flex;
  position: relative;
  background-color: #fff;
  width: 60px;
  height: 30px;
  border-radius: 15px;
  background-color: #bbb;
  cursor: pointer;
  .checkbox{
    opacity: 0;
  }
  &:after{
    content:'';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-color: #fff;
    // border: 2px solid #fff;
    box-shadow: 0px 1px 5px 0px #666;
    border-radius: 50%;
    transition: .3s;
  }
  &.active{
    background-color: #79cdbf;
    &:after{
      transform: translateX(100%) translateY(-50%);
    }
  }
}
