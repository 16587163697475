.painterOptionSelector {
	margin-top: 10px;
	.radio{
		.label{
      display: flex;
      align-items: center;
      font-size: 14px;
			color: #fff;
			cursor: pointer;
			&	+ .label {
				margin-left: 15px;
			}
		}
	}
}