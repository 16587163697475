.bookmarkContainer {
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  transition: .3s;
  opacity: 0;
  transform: translateX(-100%);

  &.show {
    transform: translateX(0);
    transition: .3s;
    opacity: 1;
    // z-index: 10;
  }

  .bookmark {
    pointer-events: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    padding: 16px 8px;
    background-color: #33353A;
    color: #fff;
    writing-mode: vertical-lr;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 0px 12px 12px 0px;
    font-size: 16px;
    cursor: pointer;

    &.active {
      color: #454B5C;
    }
  }
}

