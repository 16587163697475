.colorSelector {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;

  .colorItem{
    width: 32px;
    height: 32px;
    padding: 6px;
    &.active{
      background-color: rgba(#fff,  0.1);
      border-radius: 8px;
    }
    & + .colorItem {
      margin-left: 8px;
    }

    &.transparent{
      padding: 0;
    }
  }

  .color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;;

    &.blackColor{
      border: 1px solid #A1A4B1;
    }

    .icon {
      display: none;
      color: #fff;
    }

    &.active {
      font-size: 15px;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
      }
    }

    &.customizedColor {
      position: relative;
      overflow: hidden;
      background: linear-gradient(to bottom, #ff0000 14%, #ffa830 28%, #f4ff28 42%, #28ff2f 56%, #005dff 70%, #7200ff 84%, #ff00cb 100%);

      label {
        display: inline-block;
        width: 100%;
        height: 100%;
      }

      input {
        position: absolute;
        display: none;
      }
    }

    &.transparent {
      display: flex;
      align-items: center;
      border: 0px;
      font-size: 30px;
      color: #fff;
    }
  }
}